// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import "../stylesheets/application"
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import Highcharts from 'highcharts';
import flatpickr from "flatpickr";
const Japan = require("flatpickr/dist/l10n/ja.js").default.ja;
flatpickr.localize(Japan);

Rails.start()
Turbolinks.start()
ActiveStorage.start()
require("shopify_app")
require("packs/adminlte.js");
require("jquery");
window.jQuery = window.$ = jQuery;
require("@nathanvda/cocoon");
window.Highcharts = Highcharts;

document.addEventListener("turbolinks:load", () => {
  flatpickr("[class='flatpickr']", {
    altFormat: "F j, Y",
    dateFormat: "Y-m-d",
  })
})
